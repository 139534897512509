import { pathOr, isNil, indexOf, split, replace } from 'ramda';

const SUPPORTED_LANGUAGES = ['en', 'zh']
const DEFAULT_LANGUAGE_PREFIX = 'en'

const _getWindow = () => (typeof window !== 'undefined' ? window : { location: { pathname: '/' } })

const _getLanguagePathnamePart = () => split(`/`, _getWindow().location.pathname)[1]

/**
 * @function getLanguageFromPathname
 * Return the language definition from the browser location pathname
 */
export const getLanguageFromPathname = () => {
  const isRunningInNodeBuildProcess = pathOr(undefined, ['env', 'GATSBY_PATH_PREFIX'], process) !== undefined
  const lang = isRunningInNodeBuildProcess ? replace('/', '', process.env.GATSBY_PATH_PREFIX) : _getLanguagePathnamePart()
  if (isNil(lang) || indexOf(lang, SUPPORTED_LANGUAGES) === -1) return DEFAULT_LANGUAGE_PREFIX
  return lang
}

/**
 * @function setLanguageInPathname
 * Set the language definition in the browser location pathname
 * @param {String} newLang
 */
export const setLanguageInPathname = newLang => {
  const oldLang = _getLanguagePathnamePart()
  const canNavigate =
    !isNil(oldLang) &&
    indexOf(oldLang, SUPPORTED_LANGUAGES) !== -1 &&
    indexOf(newLang, SUPPORTED_LANGUAGES) !== -1
  if (!canNavigate) {
    console.warn(`Translation error: Cannot navigate from ${oldLang} to ${newLang}.`)
    return undefined
  }
  _getWindow().location = replace(
    oldLang, newLang, _getWindow().location.pathname
  )
}

/**
 * @function getTexts
 * Return texts based on language set
 * @param {Object} translations
 */
export const getTexts = (translations) => {
  const defaultTranslations = pathOr(undefined, [DEFAULT_LANGUAGE_PREFIX], translations)
  if (isNil(defaultTranslations)) {
    throw new Error(`Translations error: Cannot obtain default translation for language '${DEFAULT_LANGUAGE_PREFIX}'.`)
  }
  return pathOr(
    defaultTranslations, [getLanguageFromPathname()], translations
  )
}
